import React from 'utils/react';
import { connect } from 'react-redux';
import withTranslation from 'utils/react/withTranslation';
import createImmutableSelector from 'utils/immutable-selector';

import { removeBookingFromRoute } from 'modules/commuteOffer/actions';
import { openPopup } from 'modules/ui/actions';
import {
  commuteOfferIsReadOnlySelector,
  filteredAssignedVehicles,
  commuteOfferLoadedSimulationSelector,
  commuteOfferCurrentDataSelector,
} from 'modules/commuteOffer/selectors';

import AssignedBooking from './AssignedBooking';

const mapStateToProps = createImmutableSelector(
  commuteOfferIsReadOnlySelector,
  filteredAssignedVehicles,
  commuteOfferLoadedSimulationSelector,
  commuteOfferCurrentDataSelector,
  (
    isReadOnly,
    assignedVehicles,
    simulation,
    currentCommuteOffer
  ) => ({
    isReadOnly,
    assignedVehicles,
    simulation,
    currentCommuteOffer,
  })
);

const mapDispatchToProps = {
  removeBookingFromRoute,
  openPopup,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(withTranslation()(AssignedBooking));
