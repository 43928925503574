import { toaster } from 'baseui/toast';
import React from './index';
import styled from 'styled-components';
import { Button } from 'baseui/button';
import { Trans } from 'react-i18next';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  gap: 8px;
`;

const DismissButton = styled(Button)`
  && {
    border-radius: 8px;
  }
  
  &&:hover {
    background-color: rgba(192, 192, 192, 0.25);
  }
`;

export default function toasterNegative(children, props = {}) {
  const toasterKey = toaster.negative(
    <Wrapper>
      <div>
        {children}
      </div>
      <DismissButton kind='tertiary' onClick={() => toaster.clear(toasterKey)}><Trans>dismiss</Trans></DismissButton>
    </Wrapper>,
    {
      autoHideDuration: 4000,
      ...props,
      closeable: false,
    },
  );

  return toasterKey;
}