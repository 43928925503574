// import React from 'utils/react';
// import debug from 'utils/debug';

import { DragSource } from 'react-dnd';

import BookingV2 from './BookingV2';
import { toaster } from 'baseui/toast';
import React from '../../../../../utils/react';
import { Button } from 'baseui/button';
import styled from 'styled-components';
import toasterNegative from 'utils/react/toasterNegative';


const Wrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  gap: 8px;
`;

const DismissButton = styled(Button)`
  && {
    border-radius: 8px;
  }
  
  &&:hover {
    background-color: rgba(192, 192, 192, 0.25);
  }
`;

const DraggableBooking = DragSource(
  'booking',
  {
    beginDrag: props => ({ ...props.booking }),
    endDrag(props, monitor) {
      const item = monitor.getItem();
      const dropResult = monitor.getDropResult();

      if (dropResult) {
        const { t, isVehicleLocked, service_number } = dropResult;

        if (item.assigned_vehicle_id) {
          // eslint-disable-next-line no-alert
          alert('First unassigned the booking from the vehicles');
          // props.setActiveVehicleId(item.assigned_vehicle_id);
          // props.setActiveRouteStop(item.scheduled_pickup_stop_id);
          return;
        }

        if (isVehicleLocked) {
          toasterNegative(t('p.fleet.vehicle.assign.fail', { value: service_number }), { autoHideDuration: 6000 });
          return;
        }

        props.addBookingToRoute(item.uid, dropResult.agent_id);
        props.setActiveVehicleId(dropResult.agent_id);
      }
    },
  },
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  })
)(BookingV2);

export default DraggableBooking;
