import { api$CommuteOffer as api } from 'api';

import { fetchData } from 'api/net';
import debug from 'utils/debug';
// import deepmerge from 'deepmerge';
import { memoize_async } from 'utils/memoize';

import routing from 'config/routing';

const D2 = debug('u:RoutingEngine');

const getRoute = async (
  routingEngine,
  route,
  opts,
  approaches,
  curb,
  continue_straight,
  start_time,
  end_time
) =>
  D2.A.FUNCTION(
    'getRoute',
    {
      routingEngine,
      route,
      opts,
      approaches,
      curb,
      continue_straight,
      start_time,
      end_time,
    },
    async () => {
      const { url } = routing[routingEngine];
      const res = await api.getRoute(
        url(
          route,
          { approaches, curb, continue_straight, start_time, end_time },
          opts.routing_engine
        )
      );
      return res;
    }
  );

const routingEngineDriver$LineString = {
  fetch: async (route, opts) => {
    D2.S.INFO('routing:dummy:fetch', { route, opts });

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      setTimeout(async () => {
        try {
          const data = {
            routes: [
              {
                duration: 0,
                distance: 0,
                geometry: {
                  coordinates: route.map(item => [item.lon, item.lat]),
                  type: 'LineString',
                },
                legs: route.map(() => ({ duration: 0, distance: 0 })),
              },
            ],
          };
          resolve(data);
        } catch (error) {
          reject(error);
        }
      }, 0);
    });
  },
};

export const routingEngineDrivers = {
  euclidean: routingEngineDriver$LineString,
  spheroid: routingEngineDriver$LineString,
  mapbox: {
    fetch: async (route, opts) => {
      D2.S.INFO('routing:mapbox:fetch', { route, opts });
      return getRoute('mapbox', route, opts, false);
    },
  },
  osrme: {
    fetch: async (route, opts) => {
      D2.S.INFO('routing:osrme:fetch:req', { route, opts });
      const {
        curb = true,
        osrme_timestamp_mode = 'start_time',
        continue_straight,
      } = opts;
      const { osrme_continue_straight = continue_straight ?? curb } = opts;
      const res =
        osrme_timestamp_mode === 'end_time' &&
          global.GEODISC_OSRM_TDROUTE_USE_END_TIME
          ? await getRoute(
            'osrme',
            route,
            opts,
            true,
            curb,
            osrme_continue_straight,
            null,
            route[route.length - 1].scheduled_ts
          )
          : await getRoute(
            'osrme',
            route,
            opts,
            true,
            curb,
            osrme_continue_straight,
            route[0].scheduled_ts
          );
      D2.S.INFO('routing:osrme:fetch:res', { route, opts, result: res });
      return res;
    },
  },
  osrm: {
    fetch: async (route, opts) => {
      D2.S.INFO('routing:osrm:fetch:req', { route, opts });
      const { curb = true, continue_straight } = opts;
      const { osrme_continue_straight = continue_straight ?? curb } = opts;

      const res = await getRoute(
        'osrm',
        route,
        opts,
        true,
        curb,
        osrme_continue_straight
      );
      D2.S.INFO('routing:osrm:fetch:res', { route, opts, result: res });
      return res;
    },
  },
  here: {
    fetch: async (route, opts) => {
      D2.S.INFO('routing:here:fetch', { route, opts });

      const hereUrl = window.GEODISC_HERE_ROUTE_URL;
      const hereAppId = window.GEODISC_HERE_APP_ID;
      const hereAppCode = window.GEODISC_HERE_APP_CODE;
      const hereMode = opts.mode || window.GEODISC_HERE_MODE;

      const coordinates = route
        .map((herePoint, index) => {
          return `waypoint${index}=geo!${herePoint.lat},${herePoint.lon}`;
        }, [])
        .join('&');

      // eslint-disable-next-line
      const url = `${hereUrl}/calculateroute.json?app_id=${hereAppId}&app_code=${hereAppCode}&${coordinates}&mode=${hereMode}`;

      const response = await fetchData(url);
      const responseJSON = await response.json();

      D2.S.DEBUG('Response:', responseJSON);

      const responseRoute =
        responseJSON.response &&
        responseJSON.response.route &&
        responseJSON.response.route[0];

      const res =
        responseRoute && responseRoute.waypoint
          ? {
            routes: [
              {
                duration: responseRoute.leg.reduce(
                  (acc, item) => acc + item.travelTime,
                  0
                ),
                distance: responseRoute.leg.reduce(
                  (acc, item) => acc + item.length,
                  0
                ),
                geometry: {
                  coordinates:
                    responseRoute &&
                    responseRoute.leg.reduce((legAcc, leg) => {
                      const maneuver = leg.maneuver.reduce(
                        (maneuverAcc, maneuverPoint) => {
                          maneuverAcc.push([
                            maneuverPoint.position.longitude,
                            maneuverPoint.position.latitude,
                          ]);
                          return maneuverAcc;
                        },
                        []
                      );
                      return [...legAcc, ...maneuver];
                    }, []),
                  type: 'LineString',
                },
                legs: responseRoute.leg.map(leg => ({
                  duration: leg.travelTime,
                  distance: leg.length,
                })),
              },
            ],
          }
          : {
            routes: [
              {
                duration: 0,
                geometry: {
                  coordinates: route.map(item => [item.lon, item.lat]),
                  type: 'LineString',
                },
                legs: route.map(() => ({ duration: 0 })),
              },
            ],
          };

      D2.S.INFO('routing:here:fetch:result', {
        route,
        opts,
        responseJSON,
        data: res,
      });
      return res;
    },
  },
  asteria: {
    fetch: async (route, opts) => {
      D2.S.INFO('routing:asteria:fetch', { route, opts });

      const payload = {
        locations: route.map(item => ({ lon: item.lon, lat: item.lat })),
        departure_delays: route.map(() => 0),
        departure_time: route[0].scheduled_ts,
      };

      D2.S.DEBUG('Request:', payload);

      // const asteriaUrl = opts.url || window.GEODISC_ASTERIA_URL;
      // const asteriaKey = opts.key || window.GEODISC_ASTERIA_TOKEN;
      const asteriaUrl = window.GEODISC_ASTERIA_URL;
      const asteriaKey = window.GEODISC_ASTERIA_TOKEN;
      const asteriaRoutingProfile =
        opts.road_network || window.GEODISC_ASTERIA_ROUTING_PROFILE;

      const url = `${asteriaUrl}/v1/asteria/edgegraph/${asteriaRoutingProfile}/route_through`;

      const response = await fetchData(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Basic ${asteriaKey}`,
        },
        body: JSON.stringify(payload),
      });

      const responseJSON = await response.json();

      D2.S.DEBUG('Response:', responseJSON);

      return {
        routes: [
          {
            duration: responseJSON.costs[responseJSON.costs.length - 1],
            geometry: {
              coordinates: responseJSON.points.map(item => [
                item.lon,
                item.lat,
              ]),
              type: 'LineString',
            },
            legs: responseJSON.distances
              .slice(1)
              .map(value => ({ duration: value })),
          },
        ],
      };
    },
  },
  // defined_by_vehicle_settings: {
  //   fetch: async (route, defaults, opts) => {
  //     D2.S.INFO('routing:defined_by_vehicle_settings:fetch:req', {
  //       route,
  //       opts
  //     });

  //     const engine = routingEngineDrivers[opts.routing_engine_name];
  //     const res = await engine.fetch(route, opts);
  //     D2.S.INFO('routing:defined_by_vehicle_settings:fetch:res', {
  //       route,
  //       opts,
  //       result: res
  //     });
  //     return res;
  //   }
  // }
};

if (global.GEODISC_HERE_DISABLE) {
  routingEngineDrivers.here = {
    fetch: async (route, opts) => {
      D2.S.INFO('routing:dummy:fetch', { route, opts });

      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        setTimeout(async () => {
          try {
            const data = {
              routes: [
                {
                  duration: 0,
                  distance: 0,
                  geometry: {
                    coordinates: route.map(item => [item.lon, item.lat]),
                    type: 'LineString',
                  },
                  legs: route.map(() => ({ duration: 0, distance: 0 })),
                },
              ],
            };
            resolve(data);
          } catch (error) {
            reject(error);
          }
        }, 0);
      });
    },
  };
}

export const fetchRoute = async (route, opts) =>
  D2.A.FUNCTION('fetchRoute', { route, opts }, async ({ $D2 }) => {
    if (route.length === 0) {
      return null;
    }
    const { routing_engine = {} } = opts;

    const { routing_engine_name = 'osrme' } = routing_engine;

    const filteredRoute = route.map(
      ({ lon, lat, curb = routing_engine.curb ?? true, scheduled_ts }) => ({
        lon,
        lat,
        curb,
        scheduled_ts,
      })
    );
    $D2.S.INFO('route', { filteredRoute, route, routing_engine_name });

    const driver = routingEngineDrivers[routing_engine_name];

    const result = await memoize_async(driver.fetch)(filteredRoute, {
      ...opts,
      ...routing_engine,
    });

    return result;
  });
