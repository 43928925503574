import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import {
  availableBookingsSelector,
  commuteOfferCurrentDataSelector,
  commuteOfferIsReadOnlySelector,
} from 'modules/commuteOffer/selectors';
import {
  activeVehicleIdsSelector,
  activeRouteStopUidSelector,
  addPointModeSelector,
} from 'modules/ui/selectors';

import {
  setActiveRouteStop,
  cleanActiveRouteStop,
  setAddPointMode,
  cleanAddPointMode,
} from 'modules/ui/actions';

import {
  changeRouteOrder,
  deleteRoutePoint,
} from 'modules/commuteOffer/actions';

import { flyToCommuteOfferActiveStop } from 'modules/maps/actions';

import Route from './Route';

const mapStateToProps = createImmutableSelector(
  availableBookingsSelector,
  activeVehicleIdsSelector,
  activeRouteStopUidSelector,
  addPointModeSelector,
  commuteOfferIsReadOnlySelector,
  commuteOfferCurrentDataSelector,
  (
    bookings,
    activeVehicleIds,
    activeRouteStopUid,
    pointEditing,
    commuteOfferIsReadOnly,
    currentCommuteOffer,
  ) => ({
    bookings,
    activeVehicleIds,
    activeRouteStopUid,
    pointEditing,
    commuteOfferIsReadOnly,
    currentCommuteOffer,
  })
);

const mapDispatchToProps = {
  setActiveRouteStop,
  cleanActiveRouteStop,
  flyToCommuteOfferActiveStop,
  changeRouteOrder,
  setAddPointMode,
  cleanAddPointMode,
  deleteRoutePoint,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(Route);
