import React, { useState, useEffect, useMemo } from 'react';
import { styled } from 'baseui';
import debug from 'utils/debug';
import Container from '../Container';
import BookingDetails from '../BookingDetails';
import trashBin from 'assets/trashBin.svg';
import { toaster } from 'baseui/toast';
import { NODE_STATUSES } from 'utils/constants';
import { isVehicleLocked } from '../../../Vehicles/Vehicle/Vehicle';
import toasterNegative from 'utils/react/toasterNegative';

const D2 = debug('p:Logistics:Panels:AssignedBooking');

const Header = styled('div', () => {
  return {
    display: 'flex',
    justifyItems: 'center',
  };
});

const DeleteButton = styled('button', ({ $isExpanded, disabled }) => {
  return {
    position: 'absolute',
    top: '18px',
    right: '20px',
    cursor: disabled ? 'not-allowed' : 'pointer',
    height: '12px',
    width: '8px',
    border: 'none',
    opacity: 0.5,
    background: `url(${trashBin}) no-repeat center`,
    ':hover': {
      opacity: 1,
    },
  };
});

const AssignedBooking = (props) => {
  const {
    t,
    booking,
    recalculateHeight,
    removeBookingFromRoute,
    isReadOnly,
    assignedVehicles,
    simulation,
    currentCommuteOffer,
  } = props;
  const [isExpanded, setExpandDetails] = useState(true);

  const isBookingComplete = booking?.state === 'completed';
  const isBookingPickupCompleted = booking?.state === 'enroute';
  // Recalculate height on expand/collapse
  useEffect(() => {
    recalculateHeight && recalculateHeight();
    return () => {
      recalculateHeight && recalculateHeight();
    };
  }, [isExpanded]);

  const onRemoveBooking = () => {
    try {
      if (!isReadOnly) {
        removeBookingFromRoute(booking.uid);

        toaster.info(
          <>
            {t('p.booking.card.modal.remove.booking.OrderID.msg', {
              orderId: booking.data?.external_id || '',
            })}
          </>,
          {
            autoHideDuration: 4000,
            closeable: false,
          }
        );
      } else {
        toasterNegative(t('p.booking.card.modal.remove.booking.read.only'));
      }
    } catch (e) {
      toasterNegative(t('p.booking.card.modal.remove.booking.remove.OrderId.failed', {
        orderId: booking.data?.external_id || '',
      }));
    }
  };

  const removeBooking = () => {
    D2.S.FUNCTION('onRemoveBooking', { props }, () => {
      global.openWarningMessage({
        title: t('p.booking.card.modal.remove'),
        message: t('p.booking.card.modal.message'),
        buttons: [
          {
            text: t('c.messages.26b63f278101527e06a5547719568bb5'),
            action: () => {
              global.closeWarningMessage();
              onRemoveBooking(props?.t);
            },
            fill: true,
          },
        ],
      });
    });
  };

  const assignedVehicle = assignedVehicles.find(
    vehicle => vehicle?.agent_id === booking?.assigned_vehicle_id
  );

  const isLocked = useMemo(() => {
    return isVehicleLocked(currentCommuteOffer, assignedVehicle);
  }, [currentCommuteOffer, assignedVehicle]);

  const completingNode = assignedVehicle?.route?.filter((node, index) => {
    if (
      node.status === NODE_STATUSES.ASSIGNED &&
      assignedVehicle?.route[index - 1]?.status === NODE_STATUSES.COMPLETED &&
      booking?.$routeStopId === node?.id
    ) {
      return node;
    }
  });

  const isNodeCompleting = !!completingNode?.length;

  return (
    <Container style={{ cursor: 'default' }} data-testid='Booking-AssignedBooking'>
      <DeleteButton
        onClick={removeBooking}
        disabled={
          isReadOnly ||
          isBookingComplete ||
          isBookingPickupCompleted ||
          isNodeCompleting ||
          isLocked
        }
      />
      {/* <div style={{ position: 'absolute', top: '18px', right: '20px' }}>
        <Menu icon={trashBin} position='left' type='logistics'>
          <MenuButton $divider={true} onClick={removeBooking}>
            {t('p.booking.card.labels.unassign')}
          </MenuButton>
          <MenuButton onClick={unassignBooking}>
            {t('p.booking.card.labels.remove')}
          </MenuButton>
        </Menu>
      </div> */}
      <div style={{ margin: '-20px 0 0 0' }}>
        <BookingDetails expanded={true} booking={booking} t={t} {...props} />
      </div>
    </Container>
  );
};

export default AssignedBooking;
