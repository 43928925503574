import React from 'utils/react';
import useTranslation from 'utils/react/useTranslation';

import Container from './Container';

import StatusCircle from '../StatusCircle';

const Loading = ({ progress }) => {
  const { t } = useTranslation();
  return (
    <Container>
      &nbsp;&nbsp;&nbsp;
      <StatusCircle color='gray' />
      &nbsp;&nbsp; 
      {' '}
      {t('c.LoadingScreen.Loading')}
      {' '}
&nbsp;&nbsp;
      {progress ? `(${Math.round(progress * 100)}%)` : ''}
    </Container>
  );
};

export default Loading;
