/* eslint-disable */
import pagination from './pagination';
import normalizeURL from 'utils/normalizeURL';

const GEODISC_API_URL = normalizeURL(window.GEODISC_API_URL);

const GEODISC_API_V1_URL = normalizeURL(
  window.GEODISC_API_V1_URL || window.GEODISC_API_URL
);

const defaultLimit = 1000;

export default {
  logisticsapi: `${GEODISC_API_URL}/api/v2/microservices/logisticsapi`,
  logisticsapi_optimize: `${GEODISC_API_URL}/api/v2/microservices/logisticsapi_optimize`,
  datasetSchema: `${GEODISC_API_URL}/api/v2/dataset/schema`,
  user: (username) =>
    `${GEODISC_API_URL}/api/v2/user?` +
    `username=${encodeURIComponent(username)}`,
  token: `${GEODISC_API_URL}/api/v2/user/token`,
  projects:
    `${GEODISC_API_URL}/api/v2/project?` +
    [`limit=${pagination.projects}`, `order_by=name`].join('&'),
  project: (projectId) => {
    return `${GEODISC_API_URL}/api/v2/project/` + projectId;
  },
  datasets: `${GEODISC_API_URL}/api/v2/dataset?` + [`limit=0`].join('&'),
  autocomplete: `${GEODISC_API_URL}/geocoding/autocomplete`,
  geocodingSearch: `${GEODISC_API_URL}/geocoding/search`,
  reverseGeocoding: `${GEODISC_API_URL}/geocoding/resolve`,
  datasetsList: (offset, ordering, search, project) => {
    if (search) {
      return (
        `${GEODISC_API_URL}/api/v2/dataset?` +
        [
          `limit=${pagination.datasets}`,
          `offset=${offset}`,
          `order_by=${ordering}`,
          `name__contains=${search}`,
          `project=${project}`,
        ].join('&')
      );
    }

    return (
      `${GEODISC_API_URL}/api/v2/dataset?` +
      [
        `limit=${pagination.datasets}`,
        `offset=${offset}`,
        `order_by=${ordering}`,
        `project=${project}`,
      ].join('&')
    );
  },
  dataset: (id) => `${GEODISC_API_URL}/api/v2/dataset/${id}`,
  datasetCsv: (id) => `${GEODISC_API_URL}/api/v2/dataset/${id}/csv`,
  datasetZip: (ids) =>
    `${GEODISC_API_URL}/api/v2/dataset/csv?` +
    [`ids=${ids.join(',')}`].join('&'),
  geofenceSchema: `${GEODISC_API_URL}/api/v2/geofence/schema`,
  geofences: (project) =>
    `${GEODISC_API_URL}/api/v2/geofence?` +
    [`limit=0`, `order_by=-created_at`, `project=${project}`].join('&'),
  addGeofences: `${GEODISC_API_URL}/api/v2/geofence`,
  geofence: (id) => `${GEODISC_API_URL}/api/v2/geofence/${id}`,
  geofenceFilterById: (id) =>
    `${GEODISC_API_URL}/api/v2/geofence?` + [`id=${id}`].join('&'),
  geofenceCopy: (id) => `${GEODISC_API_URL}/api/v2/geofence/${id}/copy`,
  geofenceFromDataset: `${GEODISC_API_URL}/api/v2/geofence/create_from_dataset`,
  transitstopsets: (project) =>
    `${GEODISC_API_URL}/api/v2/transitstopset?` +
    [`limit=0`, `project=${project}`].join('&'),
  transitStopsBySet: (transitstopset_id, offset, limit) =>
    `${GEODISC_API_URL}/api/v2/transitstop?` +
    [
      `offset=${offset || 0}`,
      `limit=${limit || 0}`,
      `transitstopset_id=${transitstopset_id}`,
    ].join('&'),
  driverApi: `${GEODISC_API_URL}/api/v2/driver`,
  projectmemberApi: `${GEODISC_API_URL}/api/v2/projectmember`,
  vehicleApi: `${GEODISC_API_URL}/api/v2/vehicle`,
  vehicleRef: (id) => `${GEODISC_API_URL}/api/v2/vehicle/${id}`,
  vehicleSchema: `${GEODISC_API_URL}/api/v2/vehicle/schema`,
  simulationApi: `${GEODISC_API_URL}/api/v2/simulation`,
  simulationSchema: `${GEODISC_API_URL}/api/v2/simulation/schema`,
  simulationRef: (id) => `${GEODISC_API_URL}/api/v2/simulation/${id}`,
  simulations:
    `${GEODISC_API_URL}/api/v2/simulation?` +
    [`limit=0`, 'order_by=-start_time'].join('&'),
  simulationsList: (offset, ordering, search, project) => {
    if (search) {
      return (
        `${GEODISC_API_URL}/api/v2/simulation?` +
        [
          `limit=${pagination.simulations}`,
          `offset=${offset}`,
          `order_by=${ordering}`,
          `name__contains=${search}`,
          `project=${project}`,
          `deleted=false`,
        ].join('&')
      );
    }

    return (
      `${GEODISC_API_URL}/api/v2/simulation?` +
      [
        `limit=${pagination.simulations}`,
        `offset=${offset}`,
        `order_by=${ordering}`,
        `project=${project}`,
        `deleted=false`,
      ].join('&')
    );
  },
  simulation: (id) => `${GEODISC_API_URL}/api/v2/simulation/${id}`,
  simulationLaunch: (id) => `${GEODISC_API_URL}/api/v2/simulation/${id}/launch`,
  simulationVehicleLog: (id, offset, limit) => {
    return (
      `${GEODISC_API_URL}/api/v2/simulation/${id}/vehicle_log/?` +
      [`limit=${limit}`, `offset=${offset}`].join('&')
    );
  },
  simulationNodeLog: (id, offset) => {
    return (
      `${GEODISC_API_URL}/api/v2/nodelog?` +
      [
        `limit=500`,
        `assigned_vehicle__simulation__id=${id}`,
        `offset=${offset}`,
      ].join('&')
    );
  },
  simulationVehicleSet: (ids) => {
    return (
      `${GEODISC_API_URL}/api/v2/vehicle?` +
      [`limit=500`, `id__in=${[...ids].join(',')}`].join('&')
    );
  },
  simulationBookingAPI: `${GEODISC_API_URL}/api/v2/booking`,
  invalidateBookingsAPI: `${GEODISC_API_URL}/api/v2/microservices/invalidate_bookings_in_batch`,
  simulationNodeAPI: `${GEODISC_API_URL}/api/v2/node`,
  simulationNodeSet: (ids) => {
    return (
      `${GEODISC_API_URL}/api/v2/node?` +
      [`limit=500`, `id__in=${[...ids].join(',')}`].join('&')
    );
  },
  bookingsForSimulation: (id, offset = 0, limit = defaultLimit) =>
    `${GEODISC_API_URL}/api/v2/booking/?` +
    [
      `simulation__id=${id}`,
      'is_invalidated=false',
      `offset=${offset}`,
      `limit=${limit}`,
    ].join('&'),
  simulationUncalculatedBookings: (id, offset = 0, limit = defaultLimit) =>
    `${GEODISC_API_URL}/api/v2/booking/?` +
    [
      `simulation__id=${id}`,
      'is_invalidated=false',
      'state=calculation',
      `offset=${offset}`,
      `limit=${limit}`,
    ].join('&'),
  simulationBooking: (id) => `${GEODISC_API_URL}/api/v2/booking/${id}`,
  vehiclesForSimulation: (id, offset = 0, limit = defaultLimit) =>
    `${GEODISC_API_URL}/api/v2/vehicle/?` +
    [`simulation__id=${id}`, `offset=${offset}`, `limit=${limit}`].join('&'),
  nodesForSimulation: (id, offset = 0, limit = defaultLimit) =>
    `${GEODISC_API_URL}/api/v2/node/?` +
    [
      `simulation__id=${id}`,
      'booking__is_invalidated=false',
      'order_by=scheduled_ts',
      `offset=${offset}`,
      `limit=${limit}`,
    ].join('&'),
  commuteOfferProtocol: (id) =>
    `${GEODISC_API_URL}/api/v2/commuteoffer/${id}/protocol`,
  commuteOfferBinaryProtocol: (id, timezone = 'UTC') =>
    `${GEODISC_API_URL}/api/v2/commuteoffer/${id}/protocol?` +
    [`timezone=${encodeURIComponent(timezone)}`, `format=binary`].join('&'),
  commuteOffer: (id) => `${GEODISC_API_URL}/api/v2/commuteoffer/${id}`,
  commuteOffers:
    `${GEODISC_API_URL}/api/v2/commuteoffer?` +
    [`limit=0`, `deleted=false`].join('&'),
  commuteOffersByProject: (project) =>
    `${GEODISC_API_URL}/api/v2/commuteoffer?` +
    [
      `project=${project}`,
      `order_by=-id`,
      `deleted=false`,
      `limit=500`,
      `no_total_count=true`,
    ].join('&'),
  stops: window.GEODISC_MRT_STOPS_JSON_URL,
  statelessNodeScheduler: `${GEODISC_API_V1_URL}/api/v1/node_scheduler/`,
  jobResult: (id) => `${GEODISC_API_URL}/api/v2/result/${id}`,
  buildingByZipCode: (country, city, zipCode) =>
    `${GEODISC_API_URL}/api/v2/building/?` +
    [
      `postal_code=${encodeURIComponent(zipCode)}`,
      `city__name=${encodeURIComponent(city)}`,
      `city__country__name=${encodeURIComponent(country)}`,
    ].join('&'),
  buildingByAddress: (country, city, address) =>
    `${GEODISC_API_URL}/api/v2/building/?` +
    [
      `address=${encodeURIComponent(address)}`,
      `city__name=${encodeURIComponent(city)}`,
      `city__country__name=${encodeURIComponent(country)}`,
    ].join('&'),
  buildingByCode: (code) =>
    `${GEODISC_API_URL}/api/v2/building/?` +
    [`code=${encodeURIComponent(code)}`].join('&'),
  buildingById: (id) =>
    `${GEODISC_API_URL}/api/v2/building/?` + [`id=${id}`].join('&'),
  transitstopByCode: (code) =>
    `${GEODISC_API_URL}/api/v2/transitstop/?` +
    [`code=${encodeURIComponent(code)}`].join('&'),
  projectTemplateSimulationInfo: () =>
    `${GEODISC_API_URL}/api/v2/microservices/simulation_template`,
  projectServiceSimulationInfo: () =>
    `${GEODISC_API_URL}/api/v2/microservices/simulation_template_instance`,
  simulationProcessor: (id) =>
    `${GEODISC_API_URL}/api/v2/simulationprocessor/${id}`,
  nearestStop: (lon, lat, stopTypes, transitstopSets, searchRadius) => {
    const prepareQueryParams = ($paramName, $paramValue) =>
      Array.isArray($paramValue)
        ? [
            `${$paramName}_in=${$paramValue
              .map((x) => encodeURIComponent(x))
              .join(',')}`,
          ]
        : [`${$paramName}=${encodeURIComponent($paramValue)}`];
    const stopTypeParams = stopTypes
      ? prepareQueryParams('stop_type', stopTypes)
      : [];
    const transitstopSetParams = transitstopSets
      ? prepareQueryParams('transitstopset_id', transitstopSets)
      : [];
    const searchRadiusParams = searchRadius
      ? [`search_radius=${searchRadius}`]
      : [];
    return (
      `${GEODISC_API_URL}/api/v2/transitstop/nearest/?` +
      [
        ...[`lat=${lat}`, `lon=${lon}`],
        ...stopTypeParams,
        ...transitstopSetParams,
        ...searchRadiusParams,
      ].join('&')
    );
  },
  nodeAction: (nodeId) => `${GEODISC_API_URL}/api/v2/nodeaction?node=${nodeId}`,
  projectMemberByUser: (username) =>
    `${GEODISC_API_URL}/api/v2/projectmember?user__username=${encodeURIComponent(
      username
    )}`,
  mapboxToken: (projectId) =>
    `${GEODISC_API_URL}/api/v2/mapboxtoken?project=${projectId}&order_by=-modification_date`,
};
