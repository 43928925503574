import React from 'utils/react';
import moment from 'moment-timezone';
import styled from 'styled-components';

import useTranslation from 'utils/react/useTranslation';
import BookingImg from './booking.png';

import NewSimulationWindow from './NewSimulationWindow';
import LoadingWithSpinner from '../../../components/LoadingWithSpinner';

const Container = styled.div`
  width: 100vw;
  background: #080d14;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
`;

const DateHeader = styled.div`
  padding: 16px 20px;
  border-right: 1px solid #52555a;
  color: #ffffff;
  font-weight: 500;
  font-size: 28px;
  text-align: center;
`;

const TextHeader = styled.div`
  padding: 16px 20px;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.6);
  > div:first-child {
    color: #ffffff;
    font-weight: 600;
    font-size: 28px;
    margin-bottom: 8px;
  }
`;

const ImgWrapper = styled.div`
  margin: 48px 0;
`;

const Button = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px 16px 16px;
  width: 400px;
  height: 52px;
  background: #1235b2;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 70px;
  cursor: pointer;
`;

const NewSimulation = ({
  serviceDate,
  projectId,
  commuteOfferRequestUpdate,
}) => {
  const [isOpenNewSimulation, setIsOpenNewSimulation] = React.useState(false);
  const [isSimulationCreating, setIsSimulationCreating] = React.useState(false);
  const { t } = useTranslation();
  const serviceDateObject = moment(serviceDate, 'YYYY-MM-DD');
  return (
    <Container>
      <Header>
        <DateHeader>
          <div>{serviceDateObject.format('DD')}</div>
          <div>{serviceDateObject.format('MMM')}</div>
        </DateHeader>
        <TextHeader>
          <div>{t('c.NewSimulation.title')}</div>
          <div>{t('c.NewSimulation.description')}</div>
        </TextHeader>
      </Header>
      <ImgWrapper>
        <img src={BookingImg} />
      </ImgWrapper>
      <div>

        <Button
          data-testid='Create Simulation'
          onClick={() => setIsOpenNewSimulation(true)}
        >
          {t('c.NewSimulation.create')}
        </Button>

      </div>
      {isOpenNewSimulation && !isSimulationCreating && (
        <NewSimulationWindow
          onClose={() => setIsOpenNewSimulation(false)}
          setIsSimulationCreating={setIsSimulationCreating}
          projectId={projectId}
          serviceDate={serviceDate}
          commuteOfferRequestUpdate={commuteOfferRequestUpdate}
        />
      )}

      {isSimulationCreating && <LoadingWithSpinner message={t('c.NewSimulation.loading')} />}
    </Container>
  );
};

export default NewSimulation;
